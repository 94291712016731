import React from 'react';
import { 
  FaRegHandshake, 
  FaCommentsDollar, 
  // FaPlane, 
  FaHiking, 
  FaBed, 
  FaCar 
} from 'react-icons/fa';

const WhyChooseUs = () => {
  const features = [
    {
      icon: <FaRegHandshake />,
      title: "Tailored Tours",
      description: "Personalized journeys matching your dreams"
    },
    {
      icon: <FaCommentsDollar />,
      title: "Best Price",
      description: "Competitive rates with transparent pricing"
    },
    // {
    //   icon: <FaPlane />,
    //   title: "Airport Transfers",
    //   description: "24/7 reliable transfer services"
    // },
    {
      icon: <FaHiking />,
      title: "Expert Guides",
      description: "Knowledgeable local tour guides"
    },
    {
      icon: <FaBed />,
      title: "Quality Hotels",
      description: "Carefully selected accommodations"
    },
    {
      icon: <FaCar />,
      title: "Safe Transport",
      description: "Modern vehicles with professional drivers"
    }
  ];

  return (
    <section className="why-choose-us">
      <div className="container">
        <div className="section-header">
          <h2>why choose us</h2>
          {/* <p className="subtitle">Discover your perfect tour with Song & Stark in three easy steps</p> */}
        </div>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div className="feature-card" key={index}>
              <div className="icon-wrapper">
                {feature.icon}
              </div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;