import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import image1 from '../assets/images/akagera3.jpg';

const Reviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    {
      image: image1,
      title: '1 Day Akagera National Park Tour',
      text: 'I had a wonderful experience with Song & Stark Tours. The tour guide was knowledgeable and friendly, and the tour was well-organized. I would highly recommend this tour to anyone interested in visiting Akagera National Park.',
      author: 'KARIN THOMAS',
      rating: 5
    },
    {
      image: image1,
      title: '1 Day Akagera National Park Tour',
      text: 'I had a wonderful experience with Song & Stark Tours. The tour guide was knowledgeable and friendly, and the tour was well-organized. I would highly recommend this tour to anyone interested in visiting Akagera National Park.',
      author: 'KARIN THOMAS',
      rating: 5
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <section className="reviews-section">
      <div 
        className="review-image" 
        style={{ backgroundImage: `url(${testimonials[currentIndex].image})` }}
      ></div>
      <div className="review-content">
        <h2>Reviews</h2>
        <h3>{testimonials[currentIndex].title}</h3>
        <div className="stars">{'★'.repeat(testimonials[currentIndex].rating)}</div>
        <p>{testimonials[currentIndex].text}</p>
        <div className="review-author">{testimonials[currentIndex].author}</div>
        <div className="slide-indicator">
          {testimonials.map((_, index) => (
            <span 
              key={index} 
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
        <button className="slider-btn left" onClick={prevSlide}>
          <FaChevronLeft />
        </button>
        <button className="slider-btn right" onClick={nextSlide}>
          <FaChevronRight />
        </button>
      </div>
    </section>
  );
};

export default Reviews; 