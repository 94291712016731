import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/style.css';
import akagera1 from '../assets/images/trip.jpg';
import akagera2 from '../assets/images/hero1.avif';
import akagera3 from '../assets/images/optimized_main.png';

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [akagera1, akagera2, akagera3];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 7000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <section id="hero">
        <div className="hero-slideshow">
          {images.map((image, index) => (
            <div
              key={index}
              className={`slide ${index === currentImageIndex ? 'active' : ''}`}
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
        </div>
        <div className="overlay">
          <h1>Come on a journey!</h1>
          <p>Discover Rwanda's breathtaking landscapes and vibrant culture on a journey of a lifetime. 
            Embark on an unforgettable adventure and immerse yourself in the beauty and wonders of Rwanda’s rich wildlife. </p>
          <Link to="/plan-trip">
            <button>Book Now</button>
          </Link>
        </div>
      </section>
      <div className="wave"></div>
    </>
  );
};

export default Hero;
