import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "../assets/styles/tripSurvey.css";
import { API_URL } from "../variables";
import Loader from "./Loader";

const api_url = `${API_URL}/custom_reservations/createReservation`;
const activities_url = `${API_URL}/activity/getAllActivities`;

const TripSurvey = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    reservation_date: '',
    departureDate: '',
    interests: [],
  });

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axios.get(activities_url);
        if (response.status === 200) {
          setActivities(response.data);
        } else {
          toast.error("Failed to load activities.");
        }
      } catch (error) {
        console.error("Error fetching activities:", error);
        toast.error("An error occurred while fetching activities.");
      } finally {
        setLoading(false);
      }
    };
    fetchActivities();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      const activityId = value;

      setFormData((prev) => ({
        ...prev,
        interests: checked
          ? [...prev.interests, activityId]
          : prev.interests.filter((interest) => interest !== activityId),
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const reservationData = {
      reservation_first_name: formData.firstName,
      reservation_last_name: formData.lastName,
      reservation_email: formData.email,
      reservation_phone: formData.phone,
      reservation_date: formData.reservation_date,
      departure_date: formData.departureDate,
      activity_ids: JSON.stringify(formData.interests),
    };

    try {
      const response = await axios.post(
        api_url,
        JSON.stringify(reservationData),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200 || response.status === 201) {
        toast.success("Reservation made successfully!");
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          reservation_date: '',
          departureDate: '',
          interests: [],
        });
      }
    } catch (error) {
      console.error("Error submitting reservation:", error);
      toast.error("There was an issue submitting your reservation. Please try again.");
    }
  };

  return (
    <div className="trip-survey">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="interests">
        <h2>Interests</h2>
        {loading ? (
          <Loader />
        ) : (
          <ul>
            {activities.map(({ activity_id, activity_name }) => (
              <li key={activity_id}>
                <input
                  type="checkbox"
                  id={activity_id}
                  name="interests"
                  value={activity_id}
                  checked={formData.interests.includes(activity_id)}
                  onChange={handleChange}
                />
                <label htmlFor={activity_id}>{activity_name}</label>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="form-section">
        <div className="form-group">
          <label htmlFor="first-name">First Name</label>
          <input
            type="text"
            id="first-name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="last-name">Last Name</label>
          <input
            type="text"
            id="last-name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="reservation_date">Arrival Date</label>
          <input
            type="date"
            id="reservation_date"
            name="reservation_date"
            value={formData.reservation_date}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="departure-date">Departure Date</label>
          <input
            type="date"
            id="departure-date"
            name="departureDate"
            value={formData.departureDate}
            onChange={handleChange}
          />
        </div>
        <button type="submit" onClick={handleSubmit}>Book Now</button>
      </div>
    </div>
  );
};

export default TripSurvey;
