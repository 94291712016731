import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../variables';
import { useImage } from '../components/ImageContext';
import { FaArrowRight } from 'react-icons/fa';
import { getToken } from '../authService';
import '../assets/styles/style.css';
import SectionLoader from './SectionLoader';

const Destinations = () => {
  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { getImageUrl } = useImage();

  useEffect(() => {
    const fetchDestinationsAndPackages = async () => {
      try {
        // Get all destinations
        const destinationsResponse = await axios.get(`${API_URL}/destination/getAllDestinations`);
        
        // Get all packages
        const token = getToken();
        const packagesResponse = await axios.get(`${API_URL}/package/getAllPackages`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });

        // Count packages for each destination
        const destinationsWithCounts = destinationsResponse.data.map(destination => {
          const packagesCount = packagesResponse.data.filter(
            pkg => pkg.destination.destination_name === destination.destination_name
          ).length;

          return {
            ...destination,
            tripCount: packagesCount
          };
        });

        setDestinations(destinationsWithCounts);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDestinationsAndPackages();
  }, []);

  const handleCardClick = (destinationName) => {
    navigate(`/tours`, { 
      state: { selectedDestination: destinationName }
    });
  };

  return (
    <section className="destinations">
      <div className="container">
        <h2>Destinations</h2>
        {loading ? (
          <SectionLoader />
        ) : (
          <div className="card-container" id="destinations">
            {destinations.map((destination) => (
              <div 
                key={destination.id} 
                className="card" 
                onClick={() => handleCardClick(destination.destination_name)}
              >
                <img 
                  src={getImageUrl(destination.destination_image)} 
                  alt={destination.destination_name} 
                />
                <div className="card-title">
                  {destination.destination_name}
                  <span>
                    ({destination.tripCount} {destination.tripCount === 1 ? 'Trip' : 'Trips'}) 
                    <FaArrowRight />
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default Destinations;
