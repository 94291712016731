import React, { useState, useEffect } from "react";
import "../assets/styles/style.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../variables";
import { getToken } from '../authService';  
import { useImage } from "../components/ImageContext";
import { FaMapMarkerAlt } from 'react-icons/fa';
import SectionLoader from './SectionLoader';

const api_url = `${API_URL}/package/getAllPackages`;

const PopularTours = () => {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getImageUrl } = useImage();

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const token = getToken();
        const response = await axios.get(api_url, {
          headers: {
            'Authorization': `Bearer ${token}`,  
            'Content-Type': 'application/json'
          },
        });

        const validTours = response.data.filter((tour) => 
          tour.package_title && tour.package_location && tour.package_price
        ).map((tour) => ({
          id: tour.package_id,
          title: tour.package_title,
          image: tour.package_image,
          summary: tour.package_summary,
          location: tour.package_location,
          country: tour.package_country,
          price: tour.package_price,
          days: tour.package_days,
          destinationName: tour.destination.destination_name,
          special: tour.package_special || "N/A",
          bestPriceGuarantee: tour.best_price_guarantee,
          freeCancelation: tour.free_cancelation,
          isPopular: tour.is_popular
        }));

        setTours(validTours);
      } catch (error) {
        console.error("Error fetching tour packages:", error.response ? error.response.data : error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTours();
  }, []);

  return (
    <section id="popular-tours" className="popular-tours-section">
      <div className="container">
        {loading ? (
          <SectionLoader />
        ) : (
          <div className="p-tour-list">
            {tours.map((tour) => (
              <Link
                to={`/tours/${tour.id}`}
                className="p-tour-link"
                key={tour.id}
              >
                <div className="p-tour-card">
                  <div className="p-tour-image">
                    <img
                      src={getImageUrl(tour.image)}
                      alt={tour.title}
                    />
                  </div>
                  <div className="p-tour-info">
                    <p className="p-tour-destination">
                      <FaMapMarkerAlt style={{ marginRight: '5px' }} />
                      {tour.destinationName}
                    </p>
                    {/* <p className="p-tour-location">{tour.location}</p> */}
                    <h3 className="p-tour-title">{tour.title}</h3>
                    <div className="p-tour-details">
                      <p className="p-tour-days">{tour.days} days</p>
                      <p className="p-tour-price">From ${tour.price}</p>
                    </div>
                    <button className="view-details-button">View Details</button>
                  </div>
                </div>
              </Link>
            ))}
            <div className="view-all-container">
              <Link to="/tours" className="view-all-button">
                View All Tours
              </Link>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default PopularTours;
