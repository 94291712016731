import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Tours from './pages/Tours';
import TourDetails from './pages/TourDetails';
import PlanTrip from './pages/PlanTrip';
import ContactUs from './pages/ContactUs';
import { ImageProvider } from './components/ImageContext';
import ScrollToTop from './ScrollToTop';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <ImageProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/tours" element={<Tours />} />
          <Route path="/tours/:id" element={<TourDetails />} />
          <Route path="/plan-trip" element={<PlanTrip />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </ImageProvider>
    </Router>
  );
}

export default App;
