import React from "react";
import "../assets/styles/style.css";
import WaveDecoImage from "../assets/images/wave-deco.png";
import { Link } from "react-router-dom";
import { FaPhone, FaEnvelope } from 'react-icons/fa';

const Banner = () => {
  return (
    <section className="banner">
      <div className="text-section">
        <h2>Want to tailor your own Trip?</h2>
        <p>
          <FaPhone className="icon" /> Don't hesitate to contact us! <br />
          <FaEnvelope className="icon" /> We have a group of experts ready to assist you anytime.
        </p>
        <Link to="/plan-trip">
          <button className="book-now">Book Now</button>
        </Link>
      </div>
      <div className="image-container">
        <div className="wave-deco">
          <img src={WaveDecoImage} alt="Wave_deco" />
        </div>
      </div>
    </section>
  );
};

export default Banner;
