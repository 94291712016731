import React from 'react';
import '../assets/styles/sectionLoader.css'; // Ensure the path is correct

const SectionLoader = () => {
  return (
    <div className="skeleton-loader-container">
      {[...Array(4)].map((_, index) => (
        <div className="skeleton-loader" key={index}>
          <div className="skeleton-image"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text short"></div>
        </div>
      ))}
    </div>
  );
};

export default SectionLoader;