import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../assets/styles/style.css'; 
import { FaBars, FaTimes } from "react-icons/fa";
import TopBar from './TopBar';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('header');
      const topBar = document.querySelector('.top-bar');
      
      if (window.scrollY > topBar.offsetHeight) {
        header.style.top = '0';
      } else {
        header.style.top = '40px';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <TopBar />
      <header className={isHomePage ? 'home-header' : ''}>
        <nav>
          <div className="logo">
            <Link to="/">
              <span className="logo-text">SONG & STARK TOURS</span>
            </Link>
          </div>
          <button 
            className={`menuToggleBtn ${menuOpen ? 'open' : ''}`}
            onClick={toggleMenu}
          >
            {menuOpen ? <FaTimes /> : <FaBars />}
          </button>
          <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/tours">Tour Packages</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li className="btn-yellow"><Link to="/plan-trip">Plan Your Trip</Link></li>
            <li className="btn-green"><Link to="/contact">Contact Us</Link></li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default Header;