import React from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';
import '../assets/styles/style.css';

const TopBar = () => {
  return (
    <div className="top-bar">
      <div className="top-bar-left">
        <FaMapMarkerAlt className="top-bar-icon" />
        <span>5th Floor F5-506, Ikaze House, Kisementi, Remera</span>
        <FaEnvelope className="top-bar-icon" />
        <span>info@songstarktours.com</span>
        <FaPhone className="top-bar-icon" />
        <span>+250 794 422 747</span>
      </div>
      <div className="top-bar-right">
        <a href="https://facebook.com" target="_blank" rel="noreferrer">
          <FaFacebookF className="top-bar-social-icon" />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noreferrer">
          <FaTwitter className="top-bar-social-icon" />
        </a>
        <a href="https://youtube.com" target="_blank" rel="noreferrer">
          <FaYoutube className="top-bar-social-icon" />
        </a>
        <a href="https://instagram.com" target="_blank" rel="noreferrer">
          <FaInstagram className="top-bar-social-icon" />
        </a>
      </div>
    </div>
  );
};

export default TopBar;
